import React from 'react'
import { graphql } from 'gatsby'
import { get } from 'lodash'

import Hero from '../components/Hero'
import Slices from '../components/Slices'
import ImageFullWidth from '../components/ImageFullWidth'
import CaseStudyDetails from '../components/CaseStudyDetails'
import Separator from '../components/Separator'

export const query = graphql`
  query CaseStudyQuery($lang: String, $uid: String!) {
    prismic {
      data: allCase_studys(uid: $uid, lang: $lang) {
        edges {
          node {
            meta: _meta {
              id
              type
              uid
              lang
              alternateLanguages {
                uid
                lang
                type
              }
            }
            metaTitle: meta_title
            metaDescription: meta_description
            metaImage: meta_image
            heroHeading: hero_heading
            heroBody: hero_body
            hero_image1
            heroImage1Sharp: hero_image1Sharp {
              childImageSharp {
                fluid(maxWidth: 850) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            hero_image2
            heroImage2Sharp: hero_image2Sharp {
              childImageSharp {
                fluid(maxWidth: 850) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            image
            imageSharp: imageSharp {
              childImageSharp {
                fluid(maxWidth: 1100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            company {
              ... on PRISMIC_Company {
                manager {
                  ... on PRISMIC_Person {
                    meta: _meta {
                      id
                      uid
                      type
                      lang
                    }
                    name
                  }
                }
                website {
                  ... on PRISMIC__ExternalLink {
                    url
                  }
                }
                details {
                  heading
                  body
                }
              }
            }
            slices {
              ... on PRISMIC_Case_studySlicesBody {
                type
                primary {
                  heading
                  body
                  link {
                    _linkType
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                    ... on PRISMIC__Document {
                      meta: _meta {
                        id
                        uid
                        type
                        lang
                      }
                    }
                  }
                  linkLabel: link_label
                  image1
                  image1Sharp: image1Sharp {
                    childImageSharp {
                      fluid(maxWidth: 850) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                  image2
                  image2Sharp: image2Sharp {
                    childImageSharp {
                      fluid(maxWidth: 850) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
              }
              ... on PRISMIC_Case_studySlicesBlockquote {
                type
                primary {
                  quote
                  reference
                  referenceTitle: reference_title
                }
              }
            }
          }
        }
      }
    }
  }
`

const PortfolioContainer = (props) => {
  const data = get(props, 'data.prismic.data.edges[0].node')
  return (
    <>
      <Hero {...data} />
      <ImageFullWidth {...data} columns="col-sm-12 col-lg-10 offset-lg-1" />
      <CaseStudyDetails {...data} />
      <Separator smallTop />
      <Slices {...data} />
    </>
  )
}

export default PortfolioContainer
