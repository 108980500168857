import React from 'react'

import css from './styles.module.scss'
import CompanyInfo from '../CompanyInfo'

// component
// ==========================================================================================
const ImageFullWidth = ({ company }) => {
  return (
    <section className={css.wrap}>
      <div className="row">
        <div className="col-sm-12 col-lg-10 offset-lg-1">
          <CompanyInfo {...company} isHorizontal />
        </div>
      </div>
    </section>
  )
}

export default ImageFullWidth
